function truncateString(str, num) {
  if (typeof str !== "undefined") {
    if (str.length > num) {
      let subStr = str.replace(/(<([^>]+)>)/gi, "")
      subStr = subStr.substring(0, num)
      return subStr + "..."
    } else {
      return str.replace(/(<([^>]+)>)/gi, "")
    }
  }
}

export const getExcerpt = (course) => {
  if (course.description && course.description !== "") {
    return truncateString(course.description, 180)
  } else {
    if (course.chapters && course.chapters.length > 0) {
      return truncateString(course.chapters[0].content, 180)
    } else {
      return ""
    }
  }
}

export const getChapterExcerpt = (chapter) => {
  if (chapter.content && chapter.content !== ""){
    return truncateString(chapter.content, 180)
  }
}